/* eslint-disable react/no-danger */
import React from 'react';
import Icon from '../../../helpers/icon';
import menuIcons from '../../Layout/icons/menuIcons';
import ModalContainer from '../ModalContainer';
import styles from './modal.module.scss';

const Modal = ({ isOpen, data, title, closeModal }) => {
  const close = () => {
    closeModal();
  };

  return (
    <ModalContainer isOpen={isOpen} variant="fullScreen">
      <div className={`${styles.container} ${isOpen ? styles.isOpen : ''}`}>
        <div className={styles.cross} onClick={close}>
          <Icon
            icon="cross"
            width="25"
            height="25"
            class="is-center"
            color="#333"
            iconSet={menuIcons}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
