import React from 'react';
import styles from './modalContainer.module.scss';

const variantPicker = (variant) => {
  switch (variant) {
    case 'fullScreen':
      return styles.isFullScreen;
    default:
      return '';
  }
};

const ModalContainer = ({ isOpen, children, variant }) => (
  <div className={`${styles.container} ${isOpen ? styles.isOpen : ''} ${variantPicker(variant)}`}>
    {children}
  </div>
);

export default ModalContainer;
