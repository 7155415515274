import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CardPasajesHalloween from '../src/components/CardPasajesHalloween/CardPasajesHalloween';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
// import Thor from '../src/components/Thor/Thor';
import SEO from '../src/helpers/seo';
// import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import './pasajes_halloween.scss';
import Buttons from '../src/components/Buttons/Buttons';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';

export const pageQuery = () => graphql`
  query pasajesHalloween($locale: String!) {
    allPasajesHalloweenHeaderBlock(
      filter: { tag: { eq: "pasajesHalloween" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPasajesHalloweenVidarCards(
      filter: { tag: { eq: "pasajesHalloween" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          mainText
          bottomText
          price1
          price2
          possibleFilters
          position
          alt
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPasajesHalloweenTextContent(
      filter: { tag: { eq: "pasajesHalloween" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
          ctaCopy
          cta
        }
      }
    }
    allPasajesHalloweenSeoData(filter: { tag: { eq: "pasajesHalloween" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPasajesHalloweenRichData(
      filter: { tag: { eq: "pasajesHalloween" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class PasajesHalloween extends Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMundo: true,
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  render() {
    const heimdallData = {
      name: this.props.data.allPasajesHalloweenHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allPasajesHalloweenHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allPasajesHalloweenHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const vidarData = {
      cards: [
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[0].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[0].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[0].node.ctaText,
        },
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[1].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[1].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[1].node.ctaText,
        },
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[2].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[2].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[2].node.ctaText,
        },
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[3].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[3].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[3].node.ctaText,
        },
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[4].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[4].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[4].node.ctaText,
        },
        {
          type: 'PAW',
          title: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.title,
          order: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.position,
          mainText: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.mainText,
          bottomText:
            this.props.data.allPasajesHalloweenVidarCards.edges[5].node.bottomText.split('<hr />'),
          priceDay: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.price1,
          possibleFilters:
            this.props.data.allPasajesHalloweenVidarCards.edges[5].node.possibleFilters,
          cta: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.cta,
          ctaText: this.props.data.allPasajesHalloweenVidarCards.edges[5].node.ctaText,
        },
      ].filter((card) => {
        const setFilters = [];
        for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
        if (!setFilters.length) return card;
        if (setFilters.every((elem) => card.possibleFilters.indexOf(elem) > -1)) return card;
      }),
    };

    const contentText = {
      title: this.props.data.allPasajesHalloweenTextContent.edges[0].node.title,
      text: this.props.data.allPasajesHalloweenTextContent.edges[0].node.description,
      cta: this.props.data.allPasajesHalloweenTextContent.edges[0].node.ctaCopy,
      ctaCopy: this.props.data.allPasajesHalloweenTextContent.edges[0].node.cta,
    };

    const contentTitle = {
      title: this.props.data.allPasajesHalloweenTextContent.edges[0].node.title,
    };

    const vidarCardsOrd = vidarData.cards.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    });

    return (
      <Layout
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        layoutFromOld
      >
        <SEO
          title={this.props.data.allPasajesHalloweenSeoData.edges[0].node._0.title}
          description={this.props.data.allPasajesHalloweenSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allPasajesHalloweenRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allPasajesHalloweenHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="pasajes-halloween-container">
              <div id="anchor" className="content-text-container">
                <TitleH3 data={contentTitle} />
                <div
                  className="content-text"
                  dangerouslySetInnerHTML={{ __html: contentText.text }}
                />
                <Buttons
                  link={this.props.data.allPasajesHalloweenTextContent.edges[0].node.cta}
                  size="alone"
                  color="orange"
                  text={this.props.data.allPasajesHalloweenTextContent.edges[0].node.ctaCopy}
                />
              </div>
              <div className="pasajes-container">
                {vidarCardsOrd.map((card) => (
                  <CardPasajesHalloween
                    locale={this.props.pageContext.locale}
                    key={card.title}
                    data={card}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
PasajesHalloween.propTypes = {
  PasajesHalloweenData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default PasajesHalloween;
