const menuIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M1014.662 822.66c-0.004-0.004-0.008-0.008-0.012-0.010l-310.644-310.65 310.644-310.65c0.004-0.004 0.008-0.006 0.012-0.010 3.344-3.346 5.762-7.254 7.312-11.416 4.246-11.376 1.824-24.682-7.324-33.83l-146.746-146.746c-9.148-9.146-22.45-11.566-33.828-7.32-4.16 1.55-8.070 3.968-11.418 7.31 0 0.004-0.004 0.006-0.008 0.010l-310.648 310.652-310.648-310.65c-0.004-0.004-0.006-0.006-0.010-0.010-3.346-3.342-7.254-5.76-11.414-7.31-11.38-4.248-24.682-1.826-33.83 7.32l-146.748 146.748c-9.148 9.148-11.568 22.452-7.322 33.828 1.552 4.16 3.97 8.072 7.312 11.416 0.004 0.002 0.006 0.006 0.010 0.010l310.65 310.648-310.65 310.652c-0.002 0.004-0.006 0.006-0.008 0.010-3.342 3.346-5.76 7.254-7.314 11.414-4.248 11.376-1.826 24.682 7.322 33.83l146.748 146.746c9.15 9.148 22.452 11.568 33.83 7.322 4.16-1.552 8.070-3.97 11.416-7.312 0.002-0.004 0.006-0.006 0.010-0.010l310.648-310.65 310.648 310.65c0.004 0.002 0.008 0.006 0.012 0.008 3.348 3.344 7.254 5.762 11.414 7.314 11.378 4.246 24.684 1.826 33.828-7.322l146.746-146.748c9.148-9.148 11.57-22.454 7.324-33.83-1.552-4.16-3.97-8.068-7.314-11.414z',
        ],
        tags: ['cross', 'cancel', 'close', 'quit', 'remove'],
        defaultCode: 59919,
        grid: 16,
        attrs: [],
      },
      attrs: [],
      properties: {
        ligatures: 'cross, cancel',
        name: 'cross',
        order: 1048,
        id: 272,
        prevSize: 32,
        code: 59919,
      },
      setIdx: 2,
      setId: 1,
      iconIdx: 271,
    },
    {
      icon: {
        paths: [
          'M0 738.133h1024v115.2h-1024v-115.2z',
          'M0 456.533h1024v115.2h-1024v-115.2z',
          'M0 170.667h1024v115.2h-1024v-115.2z',
        ],
        attrs: [{}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['menu-01'],
        colorPermutations: {
          '25525525519696941': [{}, {}, {}],
        },
      },
      attrs: [{}, {}, {}],
      properties: {
        order: 5,
        id: 3,
        name: 'menu-01',
        prevSize: 32,
        code: 59648,
      },
      setIdx: 0,
      setId: 2,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M350.706 811.96c-12.497-12.496-12.497-32.754 0-45.254l247.163-247.163c4.164-4.168 4.164-10.919 0-15.087l-247.163-247.163c-12.497-12.497-12.497-32.758 0-45.255 12.496-12.497 32.758-12.497 45.254 0l247.163 247.163c29.157 29.16 29.157 76.435 0 105.595l-247.163 247.163c-12.496 12.496-32.758 12.496-45.254 0z',
        ],
        attrs: [
          {
            fill: 'rgb(50, 51, 147)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['flecha'],
        colorPermutations: {
          '255255255150511471': [
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(50, 51, 147)',
        },
      ],
      properties: {
        order: 20,
        id: 14,
        name: 'flecha',
        prevSize: 32,
        code: 59654,
      },
      setIdx: 1,
      setId: 8,
      iconIdx: 2,
    },
    {
      icon: {
        paths: [
          'M212.041 372.039c12.497-12.497 32.758-12.497 45.255 0l247.163 247.164c4.168 4.168 10.92 4.168 15.088 0l247.163-247.164c12.498-12.497 32.753-12.497 45.251 0 12.498 12.496 12.498 32.758 0 45.255l-247.163 247.165c-29.158 29.158-76.433 29.158-105.591 0l-247.165-247.165c-12.496-12.497-12.496-32.758 0-45.255z',
        ],
        attrs: [
          {
            fill: 'rgb(50, 51, 147)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['flecha 2'],
        colorPermutations: {
          '255255255150511471': [
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(50, 51, 147)',
        },
      ],
      properties: {
        order: 18,
        id: 16,
        name: 'flecha-2',
        prevSize: 32,
        code: 59652,
      },
      setIdx: 1,
      setId: 8,
      iconIdx: 0,
    },
  ],
};

export default menuIcons;
