/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import Img from 'gatsby-image/withIEPolyfill';
import React, { useState } from 'react';
import tt from '../../helpers/translation';
import Buttons from '../Buttons/Buttons';
import Modal from '../Modals/Modal/Modal';
import './cardPasajesHalloween.scss';

const CardPassesExpress = ({
  data: { type, title, logo, priceDay, bottomText, alt, cta, ctaText },
  locale,
}) => {
  const laIslaMalditaCalendar = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/507/original/la-isla-maldita-calendario.pdf',
    locale
  );
  const laMuerteVivaCalendar = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/526/original/CALENDARIO_la_muerte_viva_editado.pdf',
    locale
  );

  const horrorInTexas = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/506/original/horror-in-texas-calendario.pdf',
    locale
  );

  const apocalipsisMaya = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/504/original/apocalipsis-calendario.pdf',
    locale
  );

  const recExperience = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/509/original/rec-experience-calendario.pdf',
    locale
  );

  const halloweenPassport = tt(
    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/505/original/halloween_passport_calendario.pdf',
    locale
  );

  const [isOpen, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="main">
      <Modal isOpen={isOpen} data={bottomText[1]} title={title} closeModal={handleCloseModal} />
      <div className={`card-top ${type}`}>
        <div className="card-logo-title">
          <Img fluid={logo} alt={alt} className="card-img" />
          <h4 className="card-title" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>

      <div className="container-text">
        <div className="prices-container">
          <div className="prices">
            <div className="price">{priceDay}€</div>
            <span className="day">{tt('acceso', locale)}</span>
          </div>
        </div>
        <div className="bottom-text" dangerouslySetInnerHTML={{ __html: bottomText[0] }} />
        <div className="bottom-btn">
          <Buttons link="#" size="alone" color="gris-banner" text={ctaText} />
          <div className="bottom-link-container">
            <button type="button" className="bottom-link" onClick={handleModal}>
              <span>
                <b>{tt('Condiciones', locale)}</b>
              </span>
            </button>
            <a
              href={
                title === 'La Isla Maldita'
                  ? laIslaMalditaCalendar
                  : title === 'La Muerte Viva'
                  ? laMuerteVivaCalendar
                  : title === 'Horror in Texas'
                  ? horrorInTexas
                  : title === 'Apocalipsis Maya'
                  ? apocalipsisMaya
                  : title === 'REC® Experience'
                  ? recExperience
                  : title === 'Halloween Passport'
                  ? halloweenPassport
                  : halloweenPassport
              }
              target="_blank"
              rel="noreferrer"
              className="bottom-link"
            >
              <span>
                <b>{tt('Calendario', locale)}</b>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPassesExpress;
